import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import numeral from 'numeral';
import { omit } from 'lodash';
import { Link } from 'react-router-dom';

import firebase from '../../firebase';
import { publicPageFields } from '../../shared/models/tenant';
import TenantAdminPage from '../hocs/TenantAdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import { texts } from '../../config';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const storageRef = firebase.storage().ref();

export default TenantAdminPage(function TenantAdminContact(props) {
  const { tenant, match: { params: { slug } } } = props;
  const processValues = async (values) => {
    const [file] = values.logoImageFile;
    if(file == null) return values;

    const storagePath = `tenants/${slug}/logoImageFile/${file.name}`;
    const fileRef = storageRef.child(storagePath);
    await fileRef.put(file, { contentType: file.type });
    const fileUrl = await fileRef.getDownloadURL();
    return {
      ...omit(values, ['logoImageFile']),
      logoImageFileUrl: fileUrl,
    };
  }
  console.log(11111,tenant);

  return (
    <div className="tenant-admin-page">
      <div className="d-flex justify-content-center mb-2">
        <h4>公開ページ設定</h4>
      </div>
      <div className="d-flex justify-content-end">
        <Link className="btn btn-secondary" to={`/p/${slug}/reservations/new`} target="_blank">
          公開予約受付ページを見る
          <span className="fas fa-external-link-alt ml-1" />
        </Link>
        <EditButton className="ml-2" itemRef={tenantsRef.doc(slug)} FormModal={ModelFormModal} processValues={processValues} formProps={{ title: '公開ページ設定', fields: publicPageFields() }} />
      </div>
      <div className="mt-3">
        {
          Object.entries(publicPageFields()).map(([fieldName, { type, label }]) => {
            return (
              <div key={fieldName} className="mb-2">
                <div className="text-muted small">
                  {label}
                </div>
                <div>
                  {
                    type === 'file' ? (
                      <img src={tenant[fieldName + 'Url']} style={{ maxWidth: 300 }} />
                    ) : (
                      tenant[fieldName]
                    )
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
});
