import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { pick, omitBy } from 'lodash';
import classnames from 'classnames';
import Select from 'react-select';

import firebase from '../../firebase';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const defaultState = {
  id: null,
  name: '',
  nameHP: '',
  nameGN: '',
  nameTB: '',
  price: null,
  validations: {},
  isSubmitting: false,
};
const { entries } = Object;

export default class CourseFormModal extends Component {
  constructor() {
    super();
    this.state = { ...defaultState };
  }
  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.isOpen && this.props.isOpen) {
      this.clear();
      this.setValues();
    }
  }
  clear() {
    this.setState({ ...defaultState });
  }
  setValues() {
    const { values } = this.props;
    this.setState({ ...omitBy(values, _ => _ === undefined) });
    entries(values || {}).forEach(([k, v]) => setTimeout(() => this.validate(k, v), 0));
  }
  validate = (propName, value) => {
    const { validations } = this.state;
    switch (propName) {
      case 'name': 
        this.setState({ validations: { ...validations, [propName]: !!value } });
        break;
      case 'price': 
        this.setState({ validations: { ...validations, price: value >= 0 } });
        break;
      default:
        this.setState({ validations: { ...validations, [propName]: true } });
    }
  }
  validationCss(name) {
    const { validations: { [name]: isValid } } = this.state;
    return classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
  }
  onChange = (name, { target: { value } }) => {
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onChangeNumber = (name, { target: { value: _value } }) => {
    const value = parseInt(_value, 10) || null;
    this.validate(name, value);
    this.setState({ [name]: value });
  }
  onSubmit = (event) => {
    event.preventDefault();
    if(this.isUnsubmittable()) return;
    const { onSubmit } = this.props;
    const values = pick(this.state, ['id', 'name', 'nameHP', 'nameGN', 'nameTB', 'price']);
    this.setState({ isSubmitting: true });
    onSubmit(values);
  }
  isUnsubmittable() {
    const { isSubmitting, validations } = this.state;
    return isSubmitting || ['name', 'price'].some(_ => !validations[_]);
  }
  render() {
    const { values, isOpen, onClickClose } = this.props;
    const { name, nameHP, nameGN, nameTB, price } = this.state;
    const isNew = (values || {}).id == null;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          コース{isNew ? '追加' : '編集'}
        </ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody>
            <FormGroup>
              <Label>コース名</Label>
              <Input value={name} onChange={this.onChange.bind(this, 'name')} className={this.validationCss('name')} />
            </FormGroup>
            <FormGroup>
              <Label>HP名</Label>
              <Input value={nameHP} onChange={this.onChange.bind(this, 'nameHP')} className={this.validationCss('nameHP')} />
            </FormGroup>
            <FormGroup>
              <Label>GN名</Label>
              <Input value={nameGN} onChange={this.onChange.bind(this, 'nameGN')} className={this.validationCss('nameGN')} />
            </FormGroup>
            <FormGroup>
              <Label>TB名</Label>
              <Input value={nameTB} onChange={this.onChange.bind(this, 'nameTB')} className={this.validationCss('nameTB')} />
            </FormGroup>
            <FormGroup>
              <Label>価格</Label>
              <Input value={price || ''} type="number" onChange={this.onChangeNumber.bind(this, 'price')} className={this.validationCss('price')} placeholder="0" />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClickClose}>Cancel</Button>
            <Button type="submit" color="primary" onClick={this.onSubmit} disabled={this.isUnsubmittable()}>保存</Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

