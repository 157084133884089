import React, { Component } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';

import TenantAdminPage from '../hocs/TenantAdminPage';
import TenantHeaderNav from '../TenantHeaderNav';

export default TenantAdminPage(class TenantAdminRoot extends Component {
  render() {
    const { user, tenant, match: { params: { slug } } } = this.props;
    return (
      <div className="tenant-admin-root">
        <Nav vertical>
          <NavItem>
            <Link to={`/t/${slug}/admin/general`} className="nav-item">一般設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/contact`} className="nav-item">公開ページ設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/users`} className="nav-item">ユーザー管理</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/courses`} className="nav-item">コース設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/tags`} className="nav-item">予約タグ設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/customerTags`} className="nav-item">顧客属性設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/allergies`} className="nav-item">アレルギー設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/tableTypes`} className="nav-item">テーブルタイプ設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/tables`} className="nav-item">テーブル設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/tableGroups`} className="nav-item">テーブルグループ設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/tableCombinables`} className="nav-item">結合可能テーブル群設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/serviceCredentials`} className="nav-item">予約サイト ログイン設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/hpOtherLabels`} className="nav-item">HP看板設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/routeAliases`} className="nav-item">経路表示名設定</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/calendar`} className="nav-item">カレンダー連携</Link>
          </NavItem>
          <NavItem>
            <Link to={`/t/${slug}/admin/publicReservationUrl`} className="nav-item">自店舗予約受付用画面URL</Link>
          </NavItem>
        </Nav>
      </div>
    );
  }
});
