import React, { Component, Fragment } from 'react';
import { orderBy, get, keyBy, range, isEmpty, omit, pick } from 'lodash';
import { Button } from 'reactstrap';
import { format as formatDate, setHours, startOfHour, endOfDay, differenceInMinutes, addMinutes } from 'date-fns';
import qs from 'qs';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';

import firebase from '../../../firebase';
import { fullPathWithParams } from '../../../utils';
import TenantPage from '../../hocs/TenantPage';
import TenantHeaderNav from '../../TenantHeaderNav';
import TenantScheduleRow from './TenantScheduleRow';
import ReservationModal from '../../modals/ReservationModal';
import './index.css';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');
const customRoutesRef = db.collection('customRoutes');
const { keys } = Object;
const START_HOURS = 10;
const END_HOURS = 29;
const COLUMN_WIDTH = 30;
const ROW_HEIGHT = 60;

export default TenantPage(class TenantSchedule extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.setInitialDate();
    this.listenCustomRoutes();
    this.listenReservations();
    this.listenTables();
    this.listenCourses();
    this.listenTags();
    this.listenStopReceptionJobs();
    this.listenUnstopReceptionJobs();
    this.setTimebarInterval();
  }
  componentWillUnmount() {
    this.unsetTimebarInterval();
  }
  setTimebarInterval() {
    const timer = setInterval(() => {
      this.setState({ now: new Date() });
    }, 60000);
    this.setState({ timebarIntervalTimer: timer });
  }
  unsetTimebarInterval() {
    const { timebarIntervalTimer: timer } = this.state;
    timer && clearInterval(timer);
  }
  listenCustomRoutes() {
    customRoutesRef
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        this.setState({ customRoutes: snapshot.docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  componentDidUpdate(prevProps, prevState) {
    if(['location'].some(_ => prevProps[_] !== this.props[_])) {
      this.unlistenReservations && this.unlistenReservations();
      this.listenReservations();
    }
  }
  setInitialDate() {
    const { location: { search } } = this.props;
    const { date } = qs.parse(search.slice(1));
    if(date) return;
    const { history, location } = this.props;
    const path = fullPathWithParams({ date: formatDate(new Date(), 'YYYY-MM-DD') }, location);
    history.replace(encodeURI(path));
  }
  listenReservations() {
    const { match: { params: { slug } } } = this.props;
    const date = this.currentDate();
    this.unlistenReservations = tenantsRef
      .doc(slug)
      .collection('reservations')
      .where('startAt', '>=', date)
      .where('startAt', '<=', endOfDay(date))
      .onSnapshot(({ docs }) => {
        const reservations = docs.map(_ => ({ ..._.data(), id: _.id }));
        this.setState({ reservations })
      });
  }
  listenTables() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('tables')
      .orderBy('createdAt')
      .onSnapshot(({ docs }) => {
        this.setState({ tables: docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  listenCourses() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('courses')
      .orderBy('createdAt')
      .onSnapshot(({ docs }) => {
        this.setState({ courses: docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  listenTags() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
    .collection('tags')
      .orderBy('createdAt')
      .onSnapshot(({ docs }) => {
        this.setState({ tags: docs.map(_ => ({ ..._.data(), id: _.id })) });
      });
  }
  listenStopReceptionJobs() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('stopReceptionJobs')
      .onSnapshot(({ docs }) => {
        const stopReceptionJobs = docs.map(_ => ({ ..._.data(), id: _.id, ref: _.ref, }));
        this.setState({ stopReceptionJobs, stopReceptionJobsById: keyBy(stopReceptionJobs, 'id') });
      });
  }
  listenUnstopReceptionJobs() {
    const { match: { params: { slug } } } = this.props;
    tenantsRef
      .doc(slug)
      .collection('unstopReceptionJobs')
      .onSnapshot(({ docs }) => {
        const unstopReceptionJobs = docs.map(_ => ({ ..._.data(), id: _.id, ref: _.ref, }));
        this.setState({ unstopReceptionJobs, unstopReceptionJobsById: keyBy(unstopReceptionJobs, 'id') });
      });
  }
  currentDate() {
    const { location: { search } } = this.props;
    const { date } = qs.parse(search.slice(1));
    return new Date(...(date ? [date] : []));
  }
  onChangeDate = (value) => {
    const date = formatDate(value.toDate(), 'YYYY-MM-DD');
    const { history, location } = this.props;
    const path = fullPathWithParams({ date }, location);
    history.replace(encodeURI(path));
  }
  onClickReservation = (reservation) => {
    this.setState({ targetReservationId: reservation.id });
    this.openModal();
  }
  openModal = () => this.setState({ shouldShowModal: true })
  closeModal = () => this.setState({ shouldShowModal: false })
  onReservationMoveEnd = async ({ reservation, date, targetTable, sourceTable }) => {
    const { user, match: { params: { slug } } } = this.props;
    const { tables = [] } = this.state;
    const tablesById = keyBy(tables, 'id');
    const { id, startAt, endAt, nameKana, peopleCount, route, versions = [], tableIds = {} } = reservation;
    const reservationTitle = `${nameKana}様 ${peopleCount}名`;
    const sourceTableName = sourceTable != null ? sourceTable.name : '未割り当て';
    const targetTableName = targetTable != null ? targetTable.name : '未割り当て';
    const minutes = differenceInMinutes(endAt.toDate(), startAt.toDate());
    if(!window.confirm(`${sourceTableName}の予約「${reservationTitle}」を${targetTableName}の${formatDate(date, 'HH:mm')}に移動しますか？`)) return;
    const newVersions = [...versions, omit(reservation, 'versions')];
    const newTableIds = { ...omit(tableIds, (sourceTable || {}).id), ...(targetTable != null ? { [targetTable.id]: true } : {}) };
    const newCapacity = keys(newTableIds).map(_ => (tablesById[_] || {}).capacity || 0).reduce((x, y) => x + y, 0);
    try {
      await tenantsRef.doc(slug).collection('reservations').doc(id).update({
        tableIds: newTableIds,
        startAt: date,
        endAt: addMinutes(date, minutes),
        versions: newVersions,
        type: 'update',
        receptedBy: pick(user, ['uid', 'name']),
        receptedAt: new Date(),
        capacity: newCapacity,
        isOverflowing: peopleCount > newCapacity,
        route: 'phone',
      });
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  }
  onClickStop = async () => {
    if(!window.confirm('本当にグルメサイト予約受付停止しますか？')) return;

    const { tenant, user, match: { params: { slug } } } = this.props;
    const currentDate = this.currentDate();
    await tenantsRef.doc(slug).collection('stopReceptionJobs').doc(formatDate(currentDate, 'YYYY-MM-DD')).set({
      createdAt: new Date(),
      status: 'initial',
    });
  }
  onClickUnstop = async () => {
    if(!window.confirm('本当にグルメサイト予約受付停止を解除しますか？')) return;

    const { tenant, user, match: { params: { slug } } } = this.props;
    const currentDate = this.currentDate();
    await tenantsRef.doc(slug).collection('unstopReceptionJobs').doc(formatDate(currentDate, 'YYYY-MM-DD')).set({
      createdAt: new Date(),
      status: 'initial',
    });
  }
  onClickDismissJobStatusMessage = async () => {
    const lastJob = this.lastJob();
    await lastJob.ref.update({
      hasDismissedMessage: true,
    });
  }
  lastJob = () => {
    const { stopReceptionJobsById = {}, unstopReceptionJobsById = {}, } = this.state;
    const stopReceptionJob = stopReceptionJobsById[formatDate(this.currentDate(), 'YYYY-MM-DD')];
    const unstopReceptionJob = unstopReceptionJobsById[formatDate(this.currentDate(), 'YYYY-MM-DD')];
    return orderBy([{ ...stopReceptionJob, type: 'stopReceptionJob' }, { ...unstopReceptionJob, type: 'unstopReceptionJob' }].filter(_ => _.createdAt), _ => _.createdAt.toDate(), 'desc')[0];
  }
  renderJobStatusMessage() {
    const lastJob = this.lastJob();
    if(lastJob == null) return null;

    if(!lastJob.hasDismissedMessage) {
      const content = {
        initial: null,
        completed: (
          <div className="alert alert-success">
            {
              ({
                stopReceptionJob: 'グルメサイト予約受付停止が完了しました',
                unstopReceptionJob: 'グルメサイト予約受付停止の解除が完了しました',
              })[lastJob.type]
            }
            <button className="close" onClick={this.onClickDismissJobStatusMessage}>
              <span>&times;</span>
            </button>
          </div>
        ),
        failed: (
          <div className="alert alert-danger">
            {lastJob.errorMessage}
            <button className="close" onClick={this.onClickDismissJobStatusMessage}>
              <span>&times;</span>
            </button>
          </div>
        ),
      }[lastJob.status];
      return content;
    }
  }
  checkStopJobStatus() {
  }
  render() {
    const { tenant, user, match: { params: { slug } } } = this.props;
    const { customRoutes = [], reservations = [], tables = [], courses = [], tags = [], shouldShowModal = false, targetReservationId, now = new Date(), } = this.state;
    const lastJob = this.lastJob();
    const filteredReservations = reservations.filter(_ => !_.cancelReason);
    const targetReservation = filteredReservations.find(_ => _.id === targetReservationId);
    const hours = range(START_HOURS, END_HOURS);
    const waitings = filteredReservations.filter(_ => isEmpty(_.tableIds));
    const isStopped = lastJob && lastJob.type === 'stopReceptionJob' && lastJob.status !== 'initial';
    const isUnstopped = lastJob && lastJob.type === 'unstopReceptionJob' && lastJob.status !== 'initial';
    const nextAction = lastJob && lastJob.type === 'stopReceptionJob' ? 'unstop' : 'stop';
    const offsetHours = differenceInMinutes(now, startOfHour(setHours(now, START_HOURS))) / 60;

    return (
      <div className="tenant-calendar" style={{ minWidth: 900 }}>
        <TenantHeaderNav slug={slug} user={user} tenant={tenant} />
        <div className="container-fluid pt-5 mt-5">
          <h4 className="text-center mb-4">予約スケジュール</h4>
          <div>
            {this.renderJobStatusMessage()}
          </div>
          <div className="d-flex justify-content-between mb-2">
            <DatePicker
              dateFormat="YYYY/MM/DD"
              selected={moment(this.currentDate())}
              onChange={this.onChangeDate}
              className="form-control"
            />
            <div>
              <Button color="danger" onClick={this[({ stop: 'onClickStop', unstop: 'onClickUnstop' })[nextAction]]} disabled={lastJob && lastJob.status === 'initial'}>
                {
                  lastJob && lastJob.status === 'initial' ? (
                    <span>
                      <span className="fas fa-spin fa-spinner mr-1" />
                      処理中...
                    </span>
                  ) : ({
                    stop: 'グルメサイト予約受付停止',
                    unstop: 'グルメサイト予約受付停止を解除',
                  })[nextAction]
                }
              </Button>
            </div>
          </div>
        </div>
        <div className="position-relative" style={{ overflow: 'hidden', zIndex: 0 }}>
          <table className="table table-bordered sticky-table" style={{ height: 'calc(100vh - 200px)' }}>
            <thead className="thead-light border-bottom">
              <tr>
                <th colSpan="2" style={{ width: COLUMN_WIDTH * 7, height: ROW_HEIGHT, }}></th>
                <th className="p-0"></th>
                {
                  hours.map((hour) => {
                    return (
                      <th key={hour} className="border-0 text-center" colSpan={4} style={{ width: COLUMN_WIDTH * 4, height: ROW_HEIGHT }}>
                        {formatDate(startOfHour(setHours(new Date(), hour)), 'HH:mm')}
                      </th>
                    );
                  })
                }
              </tr>
            </thead>
            <tbody className="position-relative">
              <div className="position-absolute" style={{ top: 0, left: COLUMN_WIDTH * 9 + offsetHours * COLUMN_WIDTH * 4, width: 1, backgroundColor: 'red', height: '100%', zIndex: 1, }} />
              <TenantScheduleRow {...{ date: this.currentDate(), rowHeight: ROW_HEIGHT, columnWidth: COLUMN_WIDTH, reservations: waitings, hours, startHours: START_HOURS }} onClickReservation={this.onClickReservation} onReservationMoveEnd={this.onReservationMoveEnd} />
              {
                tables.map((table) => {
                  const tableReservations = filteredReservations.filter(_ => (_.tableIds || {})[table.id]);
                  return (
                    <TenantScheduleRow key={table.id} {...{ slug, date: this.currentDate(), rowHeight: ROW_HEIGHT, columnWidth: COLUMN_WIDTH, table, reservations: tableReservations, hours, startHours: START_HOURS }} onClickReservation={this.onClickReservation} onReservationMoveEnd={this.onReservationMoveEnd} />
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <ReservationModal customRoutes={customRoutes} slug={slug} onClickClose={this.closeModal} isOpen={shouldShowModal} reservation={targetReservation} tables={tables} courses={courses} tags={tags} />
      </div>
    );
  }
});
