import React, { Component } from 'react';
import { Button, Navbar, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import jaLocale from 'date-fns/locale/ja';

import { canAdministrate, canReadCustomers } from '../abilities';
import firebase from '../firebase';

const auth = firebase.auth();

export default class TenantHeaderNav extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }
  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  signOut = () => {
    auth.signOut();
    window.location.reload();
  }
  render() {
    const { showsDate = false, slug, user: { name, role }, tenant, onClickHome, renderButtons } = this.props;
    const { isOpen } = this.state;
    return (
      <Navbar fixed="top" dark className="bg-dark">
        <Nav navbar>
          <NavItem>
            {
              onClickHome ? (
                <Button color="link" onClick={onClickHome}>
                  <span className="fas fa-home text-light fa-2x" />
                </Button>
              ) : (
                <Link className="btn btn-link" to={`/t/${slug}`}>
                  <span className="fas fa-home text-light fa-2x" />
                </Link>
              )
            }
          </NavItem>
        </Nav>
        <div className="text-white flex-grow-1">
          <div>
            {tenant && tenant.name}
          </div>
          {
            showsDate && (
              <div className="small">
                {formatDate(new Date(), 'MM/DD (dd)', { locale: jaLocale })}
              </div>
            )
          }
        </div>
        <div className="d-flex align-items-center mr-2">
          {
            renderButtons && renderButtons()
          }
        </div>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={isOpen} navbar>
          <div className="text-white py-2">
            {name} でログイン中
          </div>
          <Nav className="ml-3" navbar>
            <NavItem>
              <Link className="nav-link" to={`/t/${slug}/reservations`}>予約一覧</Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to={`/t/${slug}/schedule`}>予約スケジュール</Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to={`/t/${slug}/viaPhoneNotes`}>AIロボ経由の連絡事項</Link>
            </NavItem>
            {
              canReadCustomers(role) && (
                <NavItem>
                  <Link className="nav-link" to={`/t/${slug}/customers`}>顧客一覧</Link>
                </NavItem>
              )
            }
            <NavItem>
              <Link className="nav-link" to={`/t/${slug}/stats`}>統計</Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to={`/t/${slug}/serviceSyncJobs`}>席在庫更新履歴</Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to={`/t/${slug}/serviceMails`}>予約メール一覧</Link>
            </NavItem>
            {
              canAdministrate(role) && (
                <NavItem>
                  <Link className="nav-link" to={`/t/${slug}/admin`}>管理画面</Link>
                </NavItem>
              )
            }
            <NavItem>
              <NavLink onClick={this.signOut}>ログアウト</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
};
